<template>
  <div class="ProIcon">pro</div>
</template>

<script>
export default {
  name: 'ProIcon',
}
</script>

<style lang="scss" scoped>
.ProIcon {
  padding: 0 5px 1px 5px;
  color: #FFFFFF;
  font-size: 0.625rem;
  font-weight: 300;
  text-decoration: none;
  border-radius: 2px;
  background: linear-gradient(0deg, #7d9ef1 0, #75b7f8);
  box-shadow: 0 3px 6px 0 rgba(125, 157, 241, 0.42);
}
</style>
