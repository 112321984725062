<template>
  <router-link
    class="pro-badge"
    :to="linkForButtonUpgrade"
    :disabled="disabled"
  >
    <ProIcon />
  </router-link>
</template>

<script>
import { currentWorkspace } from '@/mixins/workspace';
import ProIcon from './ProIcon';

export default {
  name: 'ProBadge',
  components: {
    ProIcon,
  },
  mixins: [
    currentWorkspace,
  ],

  computed: {
    linkForButtonUpgrade() {
      return {
        name: 'ws-settings-workspace-billing',
        params: {
          wsId: this.currentWorkspace.id,
        },
      }
    },
    showProTariffButton() {
      return !this.currentWorkspace.paid;
    },
    disabled() {
      return !this.currentWorkspace.iAmAdmin;
    },
  }
}
</script>

<style lang="scss" scoped>
.pro-badge {
  text-decoration: none;
}
</style>
